document.addEventListener('DOMContentLoaded',function() {
    
    const upload = document.getElementsByClassName('js-upload')[0];
    
    const init = function() {
        const input = upload.getElementsByTagName('input')[0],
              info = upload.getElementsByClassName('js-info')[0];
    
        const changeEventHandler = function(e) {
    
            input.value.replace(/.*(\/|\\)/, '');
            
            upload.classList.add('file-added');
            info.classList.remove('is-hidden');

            let name = input.files.item(0).name;
            info.innerHTML = name;
        }
        
        upload.onchange = changeEventHandler;
    };

    upload ? init() : false;
    
}, false);