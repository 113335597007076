import css from './sass/style.scss';

require('./js/anims.js');
require('./js/carousels.js');
require('./js/custom-select.js');
require('./js/fonts.js');
require('./js/init.js');
require('./js/map.js');
require('./js/nav.js');
require('./js/polyfills.js');
require('./js/smoothscroll.js');
require('./js/upload.js');
require('./js/validate.js');
