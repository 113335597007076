import Swiper, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Pagination]);

document.addEventListener('DOMContentLoaded',function() {
    
    const boxes = document.getElementsByClassName('js-boxesSlider'),
          cases = document.getElementsByClassName('js-casesSlider')[0];

    const boxesCarousel = function() {
        
        let swiper = [], mobile;

        const carousels = {
            destroy: function() {
                for (let i = 0; i < boxes.length; i++) {
                    if (swiper[i] != undefined) {
                        swiper[i].destroy(true,true);
                    }
                }
            },
            start: function() {
                for (let i = 0; i < boxes.length; i++) {
                    swiper[i] = new Swiper(boxes[i], {
                        centeredSlides: true,
                	    loop: false,
                	    pagination: {
                            el: boxes[i].querySelector('.swiper-pagination'),
                            clickable: true,
                            dynamicBullets: true,
                        },
                	    slidesPerView: 'auto',
                	    spaceBetween: 20,
                    });
                }
            }
        }
        
        const init = function() {
            if (window.innerWidth > 500) {
                if (mobile === true) {
                    carousels.destroy();
                    mobile = false;
                }

            } else {
                if (!mobile) {
                    carousels.start();
                    mobile = true;
                }
            }
        }
        
        init();
        window.addEventListener('resize', init);
    };

    const casesCarousel = function() {
	    const swiper = new Swiper(cases, {
    	    breakpoints: {
                1261: {
                    centeredSlides: true,
                },
            },
            loop: true,
            spaceBetween: 0,
            slidesPerView: 'auto',
            spaceBetween: 20,
            speed: 800,
            pagination: {
                el: '.c-cases .swiper-pagination',
                clickable: true,
            },
        });
    };

    window.carousels = function() {
        boxes.length > 0 ? boxesCarousel() : false;
        cases ? casesCarousel() : false;
    };

}, false)
