import { gsap, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded',function() {
    gsap.registerPlugin(ScrollTrigger);
    
    window.anims = function() {
        const fadeIn = function() {
            gsap.utils.toArray(".js-fadeIn > *").forEach(function(section) {
                gsap.from(section, {
                    scrollTrigger: {
                        trigger: section,
                        start: '-50px bottom',
                        end: 'bottom',
                        toggleActions: "play complete complete reset",
                    },
                    opacity: 0, 
                    duration: .5,
                    y: 50
                });
            });
        }

        ScrollTrigger.create({
            trigger: "#container",
            start: "top 0",
            end: self => "+=" + (document.querySelector("#container").offsetHeight - self.pin.offsetHeight),
            pin: ".js-quick",
            pinSpacing: false,
        });
     
        document.getElementsByClassName('js-fadeIn').length > 0 ? fadeIn() : false;
    };

}, false)
