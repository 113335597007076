(function() {

    const WebFont = require('webfontloader');

    WebFont.load({

        google: {
            families: ['Poppins:300,400,700,800,900:latin-ext']
        }
    });
})();

