import L from 'leaflet';
require('../../node_modules/leaflet/dist/leaflet.css');

document.addEventListener('DOMContentLoaded',function() {

    const map = document.getElementById('map');
    
    const init = function() {
        
        const logo = map.getAttribute('data-img');
        
        const mymap = L.map('map', {
            scrollWheelZoom: false
        }).setView([54.5468692, 18.5168325], 14);

        L.tileLayer('https://api.mapbox.com/styles/v1/bartoszm/ckslqs7kb67ad17r3lyphpm1k/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYmFydG9zem0iLCJhIjoiY2tzbHFuaHQ1MTdtajJ2bDR6bTRyM2dnaCJ9.z0RMvwJEz-qhM9fqHlTvQg', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            tileSize: 512,
            zoomOffset: -1
        }).addTo(mymap);
        
        const address = '<div class="o-address">' + logo +'<p><span><strong>Gdynia</strong> 81-177</span><span><strong>ul. Nasypowa</strong> 5/5</span></p></div>';
        
        const myIcon = L.divIcon({
            className: 'o-address',
            html: address
        });
        
        L.marker([54.5468692, 18.5168325], {
            icon: myIcon
        }).addTo(mymap);

        //mymap.dragging.disable();
    };
    
    
    window.contactMap = function() {
        map ? init() : false;
    }

}, false)

