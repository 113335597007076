document.addEventListener('DOMContentLoaded',function() {

    const cover = document.getElementById('cover');
    
    
    
    const init = function() {
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');   
        
        window.carousels();

        setTimeout(function() {
            window.anims();
        }, 1);        

        setTimeout(function() {
            window.contactMap();
            document.documentElement.classList.add('is-ready');
            cover.remove();
               
            
        }, 250);  
    };
    
    window.addEventListener('load', init);

}, false);